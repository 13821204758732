import { apiRootURL } from "./defaultSettings";
export const DOCUMENT_TITLE = "Autoserv | vinCE pimCE ";

export const PROXY_PORT = {
    Opel: 3001,
    "07ZR": 3002,
    Michelin: 3003,
    Ford: 3004,
    RRG: 3005,
    GtEstimate: 8889,
};

export const dockApps = [
    "citroen",
    "ds",
    "opel",
    "peugeot",
    "misterauto",
    "eurorepar",
    "actia",
];

export const iframes = {
    citroen: "https://www.citroen.fr/accueil.html",
    ds: "https://www.dsautomobiles.fr/accueil.html",
    eurorepar: "https://www.eurorepar.fr/",
    misterauto: "https://www.mister-auto.com/",
    opel: process.env.REACT_APP_PROXY_SERVER_HOST + ":" + PROXY_PORT.Opel,
    peugeot: "https://www.peugeot.fr/accueil.html",
    actia: "https://www.actia.com/fr",
    back: "https://api.sivince.io/v2/CarSelector?mode=tablette",
    diag: "https://www.aaa-data.fr/besoins/ameliorer-la-performance/",
    erp: "https://www.aaa-data.fr/besoins/renforcer-la-fidelite/",
    store: "https://www.aaa-data.fr/secteurs/retail/",
    visio: "https://www.aaa-data.fr/actualites/",
    com: "https://www.aaa-data.fr/besoins/comprendre-le-marche/",
    help: "https://www.aaa-data.fr/sinformer-sur-un-produit/",
    tecrmi: "https://rmi.ia.parts",
};

export const iframePartSelector = {
    fr: "https://api.sivince.io/workshop/CarSelector/?mode=tablette",
    en: "https://api.sivince.io/workshop/CarSelector/?mode=tablette",
};

export const iframeCarSelector = {
    fr: "https://api.sivince.io/workshop/CarSelector/?mode=tablette",
    en: "https://api.sivince.io/workshop/CarSelector/",
};

export const iframeContactSelector = {
    fr: "https://www.aaa-data.fr/besoins/renforcer-la-fidelite/",
    en: "https://www.aaa-data.fr/besoins/renforcer-la-fidelite/",
};

export const DIAG_OTHER_BRANDS = [
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
    { name: "C@iA", url: "https://run.ia.parts" },
];

export const PLUS_MENU_APPS = [
    {
        id: 0,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 1,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
    {
        id: 2,
        name: "C@iA",
        url: "https://run.ia.parts",
        img: `${apiRootURL}/media/logos/C@IA.png`,
    },
];

export const STOCK_IMAGE_URL = `https://medias2.ia.parts/sivince/pimCE.png`;
export const CAIA_FULL_IMAGE_URL = `https://medias2.ia.parts/sivince/vinCE.png`;
export const CAIA_GUII_IMAGE_URL = `https://medias2.ia.parts/sivince/360IAM.png`;
export const CAIA_GUI_SELECTOR_IMAGE_URL = `https://medias2.ia.parts/sivince/360OEM.png`;

export const TABLETTE_IMG = `${apiRootURL}/media/tab_icons/sivince.png`;
export const DATABOX_IMG = `${apiRootURL}/media/tab_icons/databox.png`;

export const LEVAM_URL = "https://oem1.ia.parts/oem_sivince.html";
